import { NextPage } from "next";
import { Loading } from "../components/Loading";
import { useUserContext } from "../context/UserContext";
import { AppLayout } from "../layouts/AppLayout";
import { Status } from "../subpages/status";

const NotFound: NextPage = () => {
  const [{ status, isAuthenticated }] = useUserContext();

  if (["init", "loading"].includes(status)) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return (
      <AppLayout title="Unknown Page">
        <Status code={404} message="Whoops!" />
      </AppLayout>
    );
  }

  return <Status code={404} message="Whoops!" />;
};

export default NotFound;
